import React, { Suspense, useEffect } from "react";
import * as ReactDOM from "react-dom";

import Layout from "../components/layout";

import {
    getCurrentUser,
    logOutAndReturnToLogin,
    handleSignResources,
    ChwebContext,
    handleRelogin,
    Loader,
    LoaderSize
} from "@chweb/commonui";

import { navigate } from "gatsby";
import { useState } from "react";
import { LocationInfo } from "../libs/location";

function injectScript(id: string, url: string) {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.setAttribute("id", id);
        script.setAttribute("async", "true");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("src", url);
        script.addEventListener("load", resolve);
        script.addEventListener("error", reject);
        document.head.appendChild(script);
    });
}

function removeScript(id: string) {
    try {
        const script = document.getElementById(id);
        if (script && script.parentNode) {
            script.parentNode.removeChild(script);
        }
        return true;
    }
    catch {
        return false;
    }
}

async function getSignedResourceUrl(context: ChwebContext, resource: string) {
    const resources = await handleSignResources([resource]);
    const signedObj = resources.resourceWithToken.find(r => r.resourceUrl === resource);
    if (!signedObj) {
        console.error("Failed to locate requested resource in the signed list", resource);
        throw new Error("Resource cannot be signed");
    }
    return `${context.getServerUrl()}/internal/${resource}?token=${signedObj.token}`;
}

const devEnvTag = process.env.GATSBY_ENV_TAG;

interface InternalAppsProps {
    location: LocationInfo
}

export default function InternalApps({ location }: InternalAppsProps) {
    const context = ChwebContext.makeIfNotValid({ react: React, reactDOM: ReactDOM }, devEnvTag);

    async function loader() {
        const scriptId = `chweb-script`;
        try {
            if (!context.getInternalApp()) {
                const scriptUrl = await getSignedResourceUrl(context, "chweb.internal.js");
                await injectScript(scriptId, scriptUrl);
            }
            return { default: context.getInternalApp() as () => JSX.Element };
        }
        catch (e) {
            console.error("Error while mounting internal component ", e);
            removeScript(scriptId);
        }
        return { default: () => <></> };
    }
    
    const [isLoggedIn, setLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const user = getCurrentUser();
        if (!user) {
            logOutAndReturnToLogin(navigate);
            setLoggedIn(false);
            return;
        }

        handleRelogin()
            .then(() => setLoggedIn(true))
            .catch(() => {
                setLoggedIn(false);
                console.error("Failed to relogin....");
                logOutAndReturnToLogin(navigate);
            });
    }, []);

    //    const InternalApp = internalAppLoader();
    const loadingComp = <div className="w3-container w3-padding-64">
        <Loader size={LoaderSize.MEDIUM} />
    </div>;

    const Component = React.lazy(loader);

    return <Layout locale={"fr"} location={location}>
        {
            isLoggedIn ?
                 <Suspense fallback={loadingComp}>
                    <Component />
                </Suspense>
                : <></>
        }
    </Layout>;
}
